import React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import {
  BriefcaseIcon,
  UsersIcon,
  GlobeIcon,
  LightningBoltIcon,
  CloudIcon,
  DownloadIcon,
} from "@heroicons/react/solid"
import Layout from "../../components/common/layout"
import Seo from "../../components/common/seo"
import RelatedPosts from "../../components/blog/RelatedPosts"
import CompanyDetails from "../../components/customer-stories/CompanyProfile"
import Section from "../../components/customer-stories/Section"
import SectionPointCard from "../../components/customer-stories/SectionPointCard"
import LevitatePreFooter from "../../components/levitate/LevitatePreFooter"
import TrophyIcon from "../../components/common/custom-icons/TrophyIcon"
import BugAntIcon from "../../components/common/custom-icons/BugAntIcon"

import dataIngestion from "../../../static/customer-stories/probo/data-ingestion-pipeline.jpg"
import alerts from "../../../static/customer-stories/probo/alerts.jpg"
import aditya from "../../../static/customer-stories/probo/aditya-chowdhry.jpeg"
import ashish from "../../../static/customer-stories/probo/ashish-garg.jpeg"
import QuoteDark from "../../components/common/QuoteDark"

const heading = "Probo Cuts Monitoring Costs by 90% with Last9"
const companyDetails = {
  id: "probo",
  name: "probo",
  points: [
    {
      name: "Prediction Markets",
      icon: <BriefcaseIcon className="h-5 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
    {
      name: "30 engineers",
      icon: <UsersIcon className="h-6 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
    {
      name: "APAC",
      icon: <GlobeIcon className="h-6 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
    {
      name: "Amazon Web Services",
      icon: <CloudIcon className="h-6 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
  ],
}

const keyPoints = [
  {
    title: "Cost",
    description: "90% more cost-efficient than New Relic and AWS Cloudwatch",
  },
  {
    title: "Open Standards",
    description: "Prometheus and OpenTelemetry compatible",
  },
  {
    title: "Automation",
    description: "GitOps-based declarative workflows for Alerting",
  },
]

const last9Advantage = [
  {
    title: "No Cost Surprises",
    description:
      "Predictable single-metric billing mode based on the number of samples ingested",
  },
  {
    title: "Open Standards",
    description: "Prometheus and OpenTelemetry compatible data warehouse",
  },
  {
    title: "GitOps Workflows",
    description:
      "Declarative workflow for alerting and dashboard automation for every team",
  },
  {
    title: "Excellent Support",
    description:
      "An engineering team that talks to its customers and is aligned with their outcomes",
  },
]

const keyResults = [
  {
    title: "Cost Effective",
    description:
      "Ability to scale monitoring to peak traffic and get granular without pricing headaches",
  },
  {
    title: "Single Pane of Observability",
    description:
      "Reduced MTTD by correlation of data between services from across platforms",
  },
  {
    title: "Democratized Alerting",
    description:
      "GitOps workflow seamlessly fits in existing developer experience",
  },
]

const paragraphStyles = "text-base leading-7 tracking-[-0.18px] text-slate-600"

const Quickwork = ({ data }) => {
  return (
    <Layout>
      <div className="px-4">
        <div className="mx-auto grid w-full max-w-6xl grid-cols-12 items-center gap-y-8 border-b-2 border-solid border-slate-100 py-10 md:py-20 lg:gap-y-16">
          <div className="col-start-1 col-end-13 row-span-1 flex flex-col gap-6 md:row-auto lg:col-start-1 lg:col-end-9">
            <span className="text-slate-500">
              <Link to="/customer-stories/" className="underline">
                Customer Stories
              </Link>
            </span>
            <div className="flex flex-col gap-6">
              <h1 className="text-3xl font-black leading-none md:text-4xl lg:text-6xl">
                {heading}
              </h1>
              <a
                href="/customer-stories/last9-levitate-customer-story-probo.pdf"
                className="flex h-10 w-fit items-center gap-2 rounded-full border-2 border-solid border-brand-green-200 pl-4 pr-5 text-center text-lg font-bold leading-5 text-brand-green-200"
                download
              >
                <DownloadIcon className="h-5 w-5" />
                Download PDF
              </a>
            </div>
          </div>
          <div className="col-start-1 col-end-13 row-span-1 lg:col-start-10 lg:col-end-13">
            <CompanyDetails companyDetails={companyDetails} />
          </div>
        </div>
        <div className="mx-auto grid w-full max-w-6xl grid-cols-12 border-b-2 border-solid border-slate-100 py-10 md:py-20 lg:gap-y-16 [&_a]:underline">
          <div className="col-start-1 col-end-13 row-span-1 flex flex-col gap-20 md:row-auto lg:col-start-1 lg:col-end-9">
            {/** Section 1 */}
            <div className="flex flex-col gap-5">
              <p className={paragraphStyles}>
                Probo is an opinion trading platform where users can invest
                based on their viewpoints on various topics such as sports,
                entertainment, economy, and finance.
              </p>
              <p className={paragraphStyles}>
                The platform allows users to trade on real events, offering
                features like market orders and the ability to exit trades to
                manage losses and maximize profits. Probo emphasizes responsible
                trading, providing news and insights to help users make informed
                decisions.
              </p>
            </div>
            {/** Section 1 end */}

            {/** Section 2 */}
            <Section
              heading="Growing Pains"
              icon={
                <BugAntIcon height={24} width={24} className="text-red-500" />
              }
            >
              <>
                <div className="mb-4 flex flex-col justify-center gap-3 md:flex-row">
                  <SectionPointCard
                    color="red"
                    title="Lack of Single Pane"
                    description="Single point of monitoring and correlation becomes difficult with multiple tools like New Relic and Cloudwatch"
                  />
                  <SectionPointCard
                    color="red"
                    title="Prohibitive Pricing"
                    description="Each tool with its multi-metric pricing model becomes expensive and unpredictable"
                  />
                  <SectionPointCard
                    color="red"
                    title="Vendor Lock-in"
                    description="Proprietary layers in the stack make portability and experimenting with emerging technologies difficult"
                  />
                </div>
                <p className={paragraphStyles}>
                  With a growing user base, Probo’s monitoring stack was getting
                  expensive for the engineering team to maintain. While traffic
                  on a normal day would hover around 200K requests per minute,
                  the platform would see jumps of &gt;2.5x in short durations
                  and reach 2.5M requests per minute during peak moments of an
                  IPL cricket match. While New Relic’s volume-and-user-based
                  pricing is predictable, it can go out of hand very quickly.
                  And, AWS Cloudwatch’s per-query pricing would make them
                  optimize their dashboards when they should ideally be focusing
                  on their system health and scaling components like RDS,
                  Elasticache, and EKS.
                </p>
                <p className={paragraphStyles}>
                  As they looked at options to optimize their costs, they were
                  also keen to move to a platform built on Open Standards that
                  allows them to have a single pane of observability in the
                  future. As a small SRE team managing the infrastructure,
                  making developers self-sufficient was another goal the Probo
                  team was trying to achieve. This means the monitoring tool
                  should be embraced by everyone in the team instead of being
                  controlled by only a few.
                </p>
              </>
            </Section>
            {/** Section 2 end */}

            {/** Section 3 */}
            <Section
              heading="The Last9 Advantage"
              icon={
                <TrophyIcon
                  height={24}
                  width={24}
                  className="text-emerald-500"
                />
              }
            >
              <>
                <div className="mb-4 flex flex-col justify-center gap-3 md:flex-row">
                  {last9Advantage.map((point, index) => (
                    <SectionPointCard
                      color="emerald"
                      title={point.title}
                      description={point.description}
                    />
                  ))}
                </div>
                <p className={paragraphStyles}>
                  Embracing Open Standards from day one and building Last9 to be
                  compatible with Prometheus and OpenTelemetry allows us to not
                  only be cost-effective but also support a large ecosystem of
                  integrations and tools for our customers. Getting started with
                  Last9 takes only 5 minutes with the OpenTelemetry integrations
                  support.
                </p>
                <QuoteDark
                  quote={
                    <p className="px-10">
                      Last9 took away the toil of setting up monitoring that
                      works flawlessly at scale, and that too, with high
                      cardinality data. We had one less thing to worry about and
                      could focus on serving our user base during the peak
                      moments.
                    </p>
                  }
                  author={{
                    name: "Ashish Garg, CTO, Probo",
                    img: ashish,
                  }}
                />
                <p className={paragraphStyles}>
                  Seeing engineering teams optimize for cost instead of granular
                  monitoring pains us at Last9. This is why we have a simple
                  single-metric billing model based on the number of events
                  ingested. That’s it. No per-user charge. No per-query charge.
                  We want you to be able to freely read the data that you’ve
                  written and use it for alerting
                </p>
                <p className={paragraphStyles}>
                  Our existing customer base, including Disney+ Hotstar and
                  Clevertap, which uses Last9 to monitor large-scale
                  live-streaming events like the Indian Premier League and
                  Cricket World Cup tournaments, also faces the same challenges
                  of sudden spikes in traffic as seen by Probo’s platform. This
                  gave their engineering team the confidence to go ahead with
                  Last9 as their monitoring and alerting tool.
                </p>

                <figure>
                  <img
                    src={dataIngestion}
                    loading="lazy"
                    className="aspect-auto w-full rounded-lg border border-slate-200"
                    alt="architecture diagram of probo and last9 data flow"
                  />
                  <figcaption className="text-left text-slate-400">
                    How data flows between Probo and Last9
                  </figcaption>
                </figure>

                <p className={paragraphStyles}>
                  Their AWS services send data to Last9 via CloudWatch Metric
                  Stream, while vmagent scrapes the self-hosted services and
                  remote writes the metrics data to Last9. A Prometheus Operator
                  spins up and manages service discovery and ingestion from
                  Kubernetes.
                </p>

                <figure>
                  <img
                    src={alerts}
                    loading="lazy"
                    className="aspect-auto w-full rounded-lg border border-slate-200"
                    alt="architecture diagram of quickwork and last9 data flow"
                  />
                  <figcaption className="text-left text-slate-400">
                    How Probo manages alerts using Declarative Alerting via IaC
                  </figcaption>
                </figure>

                <p className={paragraphStyles}>
                  Each team uses{" "}
                  <a
                    href="https://docs.last9.io/docs/alerting-via-iac"
                    target="_blank"
                  >
                    Declarative Alerting via IaC
                  </a>{" "}
                  to automate alerting and keep it updated via service discovery
                  — a pull request is raised and changes are applied via GitHub
                  Actions. As part of the automation YAML, the teams are also
                  able to declare team-specific Slack and PagerDuty channels.
                  One of the principles listed on{" "}
                  <a
                    href="https://engineering.probo.in/probo-engineering-principles-67e2e6d55a81"
                    target="_blank"
                  >
                    their engineering blog
                  </a>{" "}
                  is “high agency, always” and this allows them to democratize
                  alerting across the engineering team and not be limited to the
                  platform team.
                </p>

                <QuoteDark
                  quote={
                    <p className="px-10">
                      Automation, automation, automation. That’s how we work at
                      Probo and Last9‘s alerting just fits like a glove into
                      that workflow. Reliable, easy to integrate, and
                      exceptional customer support.
                    </p>
                  }
                  author={{
                    name: "Aditya Chowdhry, Platform Lead, Probo",
                    img: aditya,
                  }}
                />
              </>
            </Section>
            {/** Section 3 end */}

            {/** Section 4 */}
            <Section
              heading="Key Results"
              icon={
                <LightningBoltIcon
                  width={24}
                  height={24}
                  className="text-blue-500"
                />
              }
            >
              <div className="mb-4 flex flex-col justify-center gap-3 md:flex-row">
                {keyResults.map((point, index) => (
                  <SectionPointCard
                    color="blue"
                    title={point.title}
                    description={point.description}
                  />
                ))}
              </div>
              <p className={paragraphStyles}>
                With all relevant data coming into Last9, the Probo engineering
                team now has a bird’s eye view of their system’s main components
                in a master dashboard, allowing them to get to the root cause
                faster. Maintaining a large set of alerting rules, especially in
                today’s ephemeral cloud environment, is improved by automating
                the rules to be created and deleted as the service discovery is
                updated.
              </p>
              <p className={paragraphStyles}>
                Having replaced Probo’s current stack for infrastructure
                monitoring, Last9’s compatibility with OpenTelemetry now unlocks
                application performance monitoring as well as the next phase of
                a single pane of observability.
              </p>
            </Section>
            {/** Section 4 end */}

            <hr />
            <p className={paragraphStyles}>
              <Link className="underline" to="/schedule-demo">
                Schedule a demo
              </Link>{" "}
              to understand how engineering teams at Quickwork, Clevertap,
              Replit, and more are using Last9 to enable SaaS monitoring.
            </p>
          </div>

          {/** Sticky section */}
          <div className="relative col-start-1 col-end-13 row-span-1 hidden lg:col-start-10 lg:col-end-13 lg:block">
            <div className="sticky top-36 w-full divide-y-2 divide-emerald-200 rounded-3xl bg-emerald-50 px-8 py-6">
              <div className="pb-8 text-emerald-600">
                <span className="mb-3 block text-sm font-bold uppercase">
                  Key points
                </span>
                <div className="flex flex-col gap-6">
                  {keyPoints.map((point, index) => (
                    <div className="flex flex-col gap-1">
                      <span className="text-2xl font-bold leading-8">
                        {point.title}
                      </span>
                      <span className="text-balance text-xs font-bold uppercase">
                        {point.description}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col items-start gap-3 pt-8">
                <span className="text-sm font-bold uppercase leading-5 text-slate-500">
                  get a demo
                </span>
                <p className="text-sm leading-5 text-slate-500">
                  Talk to us to understand how Last9 can reduce costs, and
                  manage cardinality.
                </p>
                <Link
                  to="/schedule-demo"
                  className="flex cursor-pointer items-center justify-center rounded-full bg-emerald-500 px-6 py-2.5 font-bold text-white !no-underline"
                >
                  Schedule
                </Link>
              </div>
            </div>
          </div>
          {/** Sticky section end */}
        </div>
      </div>
      <div className="px-4">
        <RelatedPosts
          relatedPosts={data.relatedPosts.nodes.map(node => {
            return {
              ...node,
              featureImage: node.feature_image,
            }
          })}
          bgColor="bg-white"
        />
      </div>
      <LevitatePreFooter />
    </Layout>
  )
}

Quickwork.propTypes = {
  data: PropTypes.shape({
    relatedPosts: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
}

export const query = graphql`
  query MyQuery {
    relatedPosts: allGhostPost(
      filter: {
        slug: {
          in: [
            "understanding-cricket-scale"
            "observability-oss-vs-paid-vs-managed-oss"
            "taking-back-control-of-your-monitoring"
          ]
        }
      }
    ) {
      nodes {
        published_at
        authors {
          name
          profile_image
          id
        }
        title
        excerpt
        slug
        id
        feature_image
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={heading}
    description="Read how Probo uses Last9 as an alternative to New Relic and Cloudwatch for infrastructure monitoring."
    image="/thumbnails/thumbnail-last9-cust-story-probo.jpg"
  />
)

export default Quickwork
